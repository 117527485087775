import { Controller } from "@hotwired/stimulus";

/**
 * @memberof shared
 * @module OnMatchController
 * @controller
 * @property {value} contains - JSON array of string values used to match the input value
 * @property {value} name - Name of form element to check value for showing or hiding
 *
 * @description Show or hide an element based on the value of the form element that has changed.
 * When the element is show, we dispatch a "form--on-match:show" event, and when the element is
 * hidden, we dispatch a "form--on-match:hide" event.
 *
 * @example
 * <form>
 *   <div
 *     data-controller="form--on-match"
 *     data-form--on-match-contains-value="[&quot;no&quot;]"
 *     data-form--on-match-name-value="radioMe"
 *     data-action="change@document->form--on-match#show"
 *     class="fe-u-hidden"
 *  >Enable me</div>
 *   <input name="radioMe" id="radioMeNo" type="radio" value="no" />
 *   <input name="radioMe" id="radioMeYes" type="radio" value="yes" />
 * </form>
 *
 * <form>
 *   <div
 *     data-controller="form--on-match"
 *     data-form--on-match-contains-value="[&quot;no&quot;]"
 *     data-form--on-match-name-value="radioMe"
 *     data-action="change@document->form--on-match#hide"
 *     class="fe-u-hidden"
 *  >Enable me</div>
 *   <input name="radioMe" id="radioMeNo" type="radio" value="no" />
 *   <input name="radioMe" id="radioMeYes" type="radio" value="yes" />
 * </form>
 */

export default class OnMatchController extends Controller {
  static values = {
    contains: Array,
    name: String,
    className: { type: String, default: "fe-u-hidden" },
  };

  /**
   * @param target - The target of the event triggering the change
   *
   * @description show the controller element when target matches any of the contained values
   */
  show({ target }) {
    if (target.name !== this.nameValue) { return; }

    if (this.#targetContainsValue(target)) {
      this.dispatch("show", { bubbles: false });
    } else {
      this.dispatch("hide", { bubbles: false });
    }
    this.element.classList.toggle(
      this.classNameValue, !this.#targetContainsValue(target),
    );
  }


  /**
   * @param target - The target of the event triggering the change
   *
   * @description hide the controller element when target matches any of the contained values
   */
  hide({ target }) {
    if (target.name !== this.nameValue) { return; }

    if (this.#targetContainsValue(target)) {
      this.dispatch("hide", { bubbles: false });
    } else {
      this.dispatch("show", { bubbles: false });
    }
    this.element.classList.toggle(
      this.classNameValue, this.#targetContainsValue(target),
    );
  }

  showMorphed() {
    const elements = document.getElementsByName(this.nameValue);

    const element = this.#findElementForTarget(elements);

    this.show({ target: element });
  }


  hideMorphed() {
    const elements = document.getElementsByName(this.nameValue);

    const element = this.#findElementForTarget(elements);

    this.hide({ target: element });
  }

  #findElementForTarget(elements) {
    const checkedElement = Array.from(elements).find((e) => { return e.checked; });

    return checkedElement || elements[0];
  }

  #targetContainsValue(target) {
    if (target.type === "checkbox") {
      return target.checked;
    }
    if (target.type === "radio" && !target.checked) { return false; }

    return this.containsValue.includes(target.value);
  }
}
