class DynamicFormButton {
  constructor(target, url) {
    this._target = target;
    this.url = url;
  }

  toButton() {
    const button = document.createElement("button");

    button.setAttribute("formaction", this.#formAction);
    button.setAttribute("data-turbo-frame", this.#turboFrame);
    // Ensure all request can accept turbo streams including get requests
    button.setAttribute("data-turbo-stream", "true");
    button.id = "dynamic-form-button";

    this.#applyFormMethodTo(button);

    return button;
  }

  get target() {
    if (this._target.hasAttribute("data-dynamic-form-trigger")) {
      return this._target;
    }
    return this._target.closest("[data-dynamic-form-trigger]");
  }

  #applyFormMethodTo(button) {
    if (!this.#formMethod) { return; }

    if (this.#formMethod === "get") {
      button.setAttribute("formmethod", "get");
    } else {
      button.setAttribute("formmethod", "post");
    }
    button.setAttribute("name", "_method");
    button.setAttribute("value", this.#formMethod);
  }

  get #formAction() {
    const url = new URL(this.#formActionUrl, window.location.origin);
    url.searchParams.set("dynamic_form_trigger", this.#trigger_param);
    return url.toString();
  }

  get #formActionUrl() {
    if (this.target.hasAttribute("data-dynamic-form-action")) {
      return this.target.getAttribute("data-dynamic-form-action");
    }
    if (this.url) { return this.url; }

    return window.location.href;
  }

  get #turboFrame() {
    if (this.target.hasAttribute("data-dynamic-form-turbo-frame")) {
      return this.target.getAttribute("data-dynamic-form-turbo-frame");
    }

    return "_self";
  }

  get #formMethod() {
    if (!this.target.hasAttribute("data-dynamic-form-method")) { return; }

    return this.target.getAttribute("data-dynamic-form-method").toLowerCase();
  }

  get #trigger_param() {
    if (this.target.hasAttribute("data-dynamic-form-trigger-param")) {
      return this.target.getAttribute("data-dynamic-form-trigger-param");
    }

    return this.target.id;
  }
}

const dynamicFormButton = (target, url) => {
  return new DynamicFormButton(target, url).toButton();
};

export default dynamicFormButton;
