import { Controller } from "@hotwired/stimulus";

/**
 * @memberof shared
 * @module TwoDpController
 * @controller
 * @property {value} emptyZero - Convert empty string to zero value (default: false)
 *
 * @description Format an inputs value to two decimal points on change. Any value that
 * cannot be parsed to a float will not be changed.
 *
 * @example
 * <input data-controller="two-dp" />
*/
export default class TwoDpController extends Controller {
  static values = {
    emptyZero: Boolean,
  };

  connect() {
    this.abortController = new AbortController();

    this.element.addEventListener("change", (evt) => {
      this.change(evt);
    }, { signal: this.abortController.signal });
  }

  disconnect() {
    this.abortController.abort();
  }

  change({ target }) {
    let val = target.value;

    if (this.emptyZeroValue && this.#isEmpty(val)) {
      val = "0.00";
    }

    const parsedVal = val.replace(/[^\d.-]/g, "");

    if (isNaN(parsedVal) || this.#isEmpty(parsedVal)) {
      target.value = val;
    } else {
      target.value = parseFloat(parsedVal).toFixed(2);
    }
  }

  #isEmpty(str) {
    return !str || String(str).match(/^\s*$/);
  }
}
