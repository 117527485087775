export default class DirtyTracker {
  constructor() {
    this.dirtyFields = [];
  }

  start(element, triggerElement) {
    this.reset();
    this.abortController = new AbortController();
    element.addEventListener(
      "change",
      (evt) => {
        if (evt.target.name !== triggerElement.name) {
          this.dirtyFields.push(evt.target.name);
        }
      },
      { signal: this.abortController.signal },
    );
  }

  reset() {
    this.abortController?.abort();
    this.clear();
  }

  includes(attributeName, name) {
    if (!this.dirtyFields.includes(name)) {
      return false;
    }
    const inputAttributeNames = ["value", "checked"];
    return inputAttributeNames.includes(attributeName);
  }

  clear() {
    this.dirtyFields = [];
  }

  get length() {
    return this.dirtyFields.length;
  }
}
