import { Controller } from "@hotwired/stimulus";

export default class WebConsoleController extends Controller {
  static targets = ["console"];

  connect() {
    if (this.hasConsoleTarget) {
      document.addEventListener("web-console:show", this.onShowEvent.bind(this));
      document.addEventListener("web-console:hide", this.onHideEvent.bind(this));

      console.info("💻 web-console is enabled, emit 'web-console:show' or 'web-console:hide' on the document to toggle the console.");
    }
  }

  show(event) {
    event.preventDefault();
    document.dispatchEvent(new CustomEvent("web-console:show"));
  }

  hide(event) {
    event.preventDefault();
    document.dispatchEvent(new CustomEvent("web-console:hide"));
  }

  onShowEvent(event) {
    event.preventDefault();

    if (this.hasConsoleTarget) {
      this.consoleTarget.classList.remove("fe-u-hidden");

      if (REPLConsole.currentSession) {
        REPLConsole.currentSession.focus();
      }
    }
  }

  onHideEvent(event) {
    event.preventDefault();

    if (this.hasConsoleTarget) {
      this.consoleTarget.classList.add("fe-u-hidden");

      if (REPLConsole.currentSession) {
        REPLConsole.currentSession.blur();
      }
    }
  }
}
