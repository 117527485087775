import { Controller } from "@hotwired/stimulus";

/**
 * @memberof shared
 * @module DisclosureV2Controller
 * @controller
 * @property {target} trigger - The element the user clicks to expand/collapse the disclosure
 * @property {target} disclosee - The content container that will be expanded or collapsed
 * @property {value} initiallyOpen - Set the disclosure to be open initially. (Default: false)
 *
 * @description Creates a Disclosure Widget: an accessible interactive UI pattern allowing
 * the user to expand and collapse content via a trigger (typically a <button>)
 * which conveys state changes accessibily via ARIA.
 * More on disclosure widgets: https://adrianroselli.com/2020/05/disclosure-widgets.html
 *
 * @example
 * <div data-controller="disclosure-v2">
 *   <button
 *     type="button" aria-expanded="false" aria-controls="disclosee1"
 *     data-action="click->disclosure-v2#toggle" data-disclosure-v2-target="trigger"
 *   >
 *     Additional information about payroll
 *   </button>
 *   <div id="disclosee1" data-disclosure-v2-target="disclosee" hidden>
 *     <p>Lots of important information about payroll here.</p>
 *     <p>Lorem ipsum dolor…</p>
*    </div>
* </div>
*/

export default class DisclosureV2Controller extends Controller {
  static targets = ["trigger", "disclosee"];
  static values = { initiallyOpen: { type: Boolean, default: false } };

  initialize() {
    this.#setOpen(this.initiallyOpenValue);
  }

  // This is called every time the trigger button is clicked.
  toggle() {
    const button = this.triggerTarget;

    // When the user clicks the button we expect the browser to also move programmatic focus to it.
    // Most browsers auto-focus a clicked button but Safari doesn’t so let’s make sure it happens.
    // (We need focus to be within the component so that focusout fires when user moves elsewhere.)
    // More info at https://zellwk.com/blog/inconsistent-button-behavior/
    button.focus();

    // Toggle the state of the disclosure
    this.#setOpen(!this.#getOpen());
  }

  // Support the user collapsing the disclosure by clicking or tabbing outside it.
  // This is “opt-in”. To use: in your HTML add these `data-action`s on the parent element:
  // "click@document->disclosure#collapseOnLightDismiss",
  // "keyup.tab@document->disclosure#collapseOnLightDismiss"
  collapseOnLightDismiss(event) {
    if (this.hasDiscloseeTarget) {
      // If the widget is already collapsed there’s no reason to be here, so bail out.
      if (this.discloseeTarget.hidden) return;

      // If the click/keyboard event came from inside the widget rather than outside, bail out.
      if (this.element.contains(event.target)) return;

      // Otherwise, we’re good to collapse the disclosure.
      this.#collapse();
    }
  }

  #getOpen() {
    return this.open;
  }

  #setOpen(open) {
    this.open = open;
    this.#updateUI();
  }

  #updateUI() {
    if (this.#getOpen()) {
      this.#expand();
    } else {
      this.#collapse();
    }
  }

  #expand() {
    // If the widget is already expanded, bail out.
    if (this.#isExpanded()) return;

    this.triggerTarget.setAttribute("aria-expanded", true);
    this.discloseeTarget.hidden = false;
  }

  #collapse() {
    // If the widget is already collapsed, bail out.
    if (!this.#isExpanded()) return;

    this.discloseeTarget.hidden = true;
    this.triggerTarget.setAttribute("aria-expanded", false);
  }

  #isExpanded() {
    return this.triggerTarget.getAttribute("aria-expanded") === "true";
  }
}
