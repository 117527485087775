import { Controller } from "@hotwired/stimulus";
import keycode from "keycode";
import Cookies from "js-cookie";
import apiFetch from "../../shared/api_fetch";

const FOCUSABLE_ELEMENTS = [
  "a[href]:not([disabled]):not([tabindex=\"-1\"])",
  "button:not([disabled])",
  "select:not([disabled])",
  "textarea:not([disabled])",
  "input:not([disabled]):not([type=\"hidden\"])",
  "[tabindex]:not([tabindex=\"-1\"])",
  "iframe",
];

const BODY_OPEN_CLASS = "fe-Modal-open";

/**
 * @memberof shared
 * @module ModalController
 * @controller
 * @private
 * @description Only to be used as part of the design system modal
 */
export default class Modal extends Controller {
  static targets = ["dismiss"];
  static values = { dismissalUrl: String };

  connect() {
    this.isOpen = false;

    if (this.element.dataset.template) return;

    this.open();
  }

  disconnect() {
    this.close();
  }

  handleKeypress(event) {
    if (keycode(event) === "esc") {
      this.dismiss(event);
    }

    if (keycode(event) === "tab") {
      this.trapFocus(event);
    }
  }

  open() {
    this.isOpen = true;

    if (!this.element.hasAttribute("data-inline")) {
      document.body.classList.add(BODY_OPEN_CLASS);
    }

    this.previouslyFocusedElement = document.activeElement;
    this.focusableElements = this.element.querySelectorAll(FOCUSABLE_ELEMENTS.join(","));
    this.firstFocusableElement = this.focusableElements[0];
    this.lastFocusableElement = this.focusableElements[this.focusableElements.length - 1];
    this.focusFirstElement();
  }

  close() {
    this.isOpen = false;

    document.body.classList.remove(BODY_OPEN_CLASS);
    this.returnFocus();
  }

  dispatchClosedEvent() {
    this.element.dispatchEvent(new CustomEvent("freestyle.modal.closed", {
      bubbles: true,
      detail: {
        modalName: this.data.has("name") ? this.data.get("name") : false,
        dismissAction: this.hasDismissTarget ? this.dismissTarget : false,
      },
    }));
  }

  dismiss(event) {
    event.preventDefault();
    this.dispatchClosedEvent();
    this.setFeatureModalDismissalCookie();
    this.createFeatureModalDismissalRecord();
    this.element.remove();
  }

  dismissWithDefaultEvent() {
    this.setFeatureModalDismissalCookie();
    this.createFeatureModalDismissalRecord();
    this.element.remove();
  }

  returnFocus() {
    if (this.previouslyFocusedElement && document.body.contains(this.previouslyFocusedElement)) {
      this.previouslyFocusedElement.focus();
    }
  }

  focusFirstElement() {
    this.firstFocusableElement.focus();

    // Move cursor to end of any text in input using `selectionStart`.
    //
    // This property should be accessible on any input element as it returns
    // `null` if not applicable [1]. Safari has a bug and doesn't follow the
    // specified behaviour. If you access this property on an input without any
    // text content (e.g. a radio button) it will throw a type error. As a
    // workaround we're checking the element type for inputs that explicitly
    // support this property before accessing it. [2]
    //
    // [1] https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#dom-textarea/input-selectionstart
    // [2] https://html.spec.whatwg.org/multipage/input.html

    if (/^(textarea|text|search|url|telephone|password)$/.test(this.firstFocusableElement.type)) {
      this.firstFocusableElement.selectionStart = this.firstFocusableElement.value.length;
      this.firstFocusableElement.selectionEnd = this.firstFocusableElement.value.length;
    }
  }

  maintainFocus(event) {
    if (this.isOpen && !this.element.contains(event.target) && this.#isTopModal) {
      this.focusFirstElement();
    }
  }

  get #isTopModal() {
    return !(this.element.nextElementSibling?.classList?.contains("fe-Modal"));
  }

  trapFocus(event) {
    if (event.shiftKey) {
      if (document.activeElement === this.firstFocusableElement) {
        this.lastFocusableElement.focus();
        event.preventDefault();
      }
    } else if (document.activeElement === this.lastFocusableElement) {
      this.firstFocusableElement.focus();
      event.preventDefault();
    }
  }

  setFeatureModalDismissalCookie() {
    if (this.data.has("feature") && this.data.has("name")) {
      Cookies.set(`${this.data.get("name")}_feature_modal_dismissed`, true, { expires: 365 });
    }
  }

  createFeatureModalDismissalRecord() {
    if (this.data.has("feature") && this.data.has("name")) {
      const postData = {
        feature_modal_name: this.data.get("name"),
      };

      apiFetch(this.dismissalUrlValue, { method: "post", body: JSON.stringify(postData) });
    }
  }
}
