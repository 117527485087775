import * as Sentry from "@sentry/browser";
import { captureConsoleIntegration } from "@sentry/integrations";

const scriptTag = document.getElementById("sentry-script");
const gitHash = scriptTag.dataset.gitHash;
const environment = scriptTag.dataset.environment;
const dsn = scriptTag.dataset.dsn;
const enabled = scriptTag.dataset.enabled === "true";

const additionalData = {};
try {
  additionalData.allowUrls = JSON.parse(scriptTag.dataset.allowUrls).map((url) => {
    return new RegExp(`https?://${url}`);
  });
  additionalData.initialScope = { user: JSON.parse(scriptTag.dataset.scope) };
} catch (exception) {
  // ignore if parsing the tags fails
  setTimeout(() => {
    console.error("Cannot get scope or allowUrls", exception);
  }, 500);
}

window.sentryEnabled = enabled;
Sentry.init({
  enabled: enabled,
  dsn: dsn,
  release: gitHash,
  integrations: [
    captureConsoleIntegration({ levels: ["error"] }),
  ],
  environment: environment,
  sampleRate: 1.0,
  maxBreadcrumbs: 100,
  attachStacktrace: false,
  // ignore errors and urls taken from community gist https://gist.github.com/impressiver/5092952
  ignoreErrors: [
    // our own custom errors:
    "UserLoggedOutError",
    // Our errors from rollbar
    "cancelled",
    "instantSearchSDKJSBridgeClearHighlight is not defined",
    "Object doesn't support property or method 'closest'",
    "ResizeObserver loop limit exceeded",
    "Fetch is aborted",
    "Cannot get property 'clearHighlight' of undefined or null",
    "undefined is not an object (evaluating '__gCrWeb.instantSearch.clearHighlight')",
    "fetchAndApplyCosmeticRules",
    "AbortError",
    // Random plugins/extensions
    "top.GLOBALS",
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    "originalCreateNotification",
    "canvas.contentDocument",
    "MyApp_RemoveAllHighlights",
    "http://tt.epicplay.com",
    "Can\"t find variable: ZiteReader",
    "jigsaw is not defined",
    "ComboSearch is not defined",
    "http://loading.retry.widdit.com/",
    "atomicFindClose",
    // Facebook borked
    "fb_xd_fragment",
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
    "bmi_SafeAddOnload",
    "EBCallBackMessageReceived",
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    "conduitPage",
    // Network errors
    "Network error: Failed to fetch",
    "TypeError: Load failed",
  ],
  ignoreUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  ],
  ...additionalData,
  // ignoreTransactions: [],
});
